@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf") format("ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.ttf") format("ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Italic.ttf") format("ttf");
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-LightItalic.ttf") format("ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBoldItalic.ttf") format("ttf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Trade Gothic LH Extended";
  src: url("../fonts/trade-gothic-lh-extended.otf") format("opentype");
}

@font-face {
  font-family: "Trade Gothic LH Extended";
  src: url("../fonts/trade-gothic-lh-bold-extended.otf") format("opentype");
  font-weight: 700;
}

/* html,
body {
  height: 100vh;
  font-family: "Cormorant" !important;
} */

/* body {
  background-color: #ffffff;
  color: #2c302f;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
} */

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1,
a {
  font-family: "Trade Gothic LH Extended";
}

p {
  font-family: "Montserrat" !important;
}

* {
  box-sizing: border-box;
}

#btn-back-to-top {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: none;
  z-index: 1000;
  background-color: #ecc625;
}

nav {
  background-color: #ffffffd9 !important;
  height: 90px;
  box-shadow: none !important;
}

nav .navbar-container {
  padding: 0 !important;
}

nav button.navbar-toggler {
  color: #575756 !important;
}

nav .dropdown {
  color: #575756;
  background-color: transparent;
  padding-right: 53px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

nav .dropdown:hover {
  font-weight: bold;
  background-color: transparent;
}

nav .lng-option {
  text-transform: uppercase;
  color: #575756;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
}

nav .en {
  padding-right: 5px;
}

nav .languages {
  padding-right: 88px;
}

nav .languages-mobile {
  display: none;
  padding-right: 25px;
}

nav .lng-option-active {
  font-weight: bold;
}

nav .navbar-brand {
  padding-left: 89px;
}

footer {
  background: #e9c329 !important;
}

footer .lng-option-active {
  font-weight: bold;
}

.footer-container .footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container {
  padding-top: 65px;
}

.footer-container .footer-col .rg-img {
  width: 140px;
}

.footer-titles {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #585857;
}

.footer-titles h6 {
  margin-bottom: 16px;
}

.footer-info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #585857;
}

.footer-info p {
  margin-bottom: 8px;
}

footer .lng-option {
  text-transform: uppercase;
  color: #575756;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
}

.footer-info a {
  color: inherit;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media i {
  width: 15%;
}

/*.footer-container .footer-col .footer-info .footer-linkedin-img {
  width: 4%;
}*/

.phrase-container {
  margin-top: 40px;
}

.phrase-container .phrase {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.copyright-container {
  margin-top: 20px;
  padding-bottom: 20px;
}

.copyright-container .copyright {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  nav .navbar-brand {
    padding-left: 30px !important;
  }

  nav .languages {
    padding-right: 30px !important;
  }

  nav .dropdown {
    font-size: 12px !important;
    padding-right: 45px;
  }
}

@media only screen and (max-width: 991px) {
  /* CSS rules for browser widths equal to or less than 600px */
  nav .navbar-container {
    display: flex !important;
  }

  nav .nav-options {
    display: flex !important;
    flex-direction: column !important;
  }

  nav {
    height: auto !important;
  }

  nav .hamburger-container {
    display: flex !important;
  }

  nav .dropdown {
    padding-right: 0 !important;
    font-size: 14px !important;
    margin-bottom: 8px;
  }

  nav .languages {
    display: none;
  }

  nav .languages-mobile {
    display: block !important;
    padding-right: 25px;
  }

  nav .navbar-brand {
    padding-left: 5px !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    text-align: center !important;
  }

  .footer-titles {
    margin-top: 15px !important;
  }
}
